import { ACCESSIBILITY } from '../desktop/accessibility/accessibility-navigation.constants';
class AccessibilityClass {
  initFocusModeDetection(element) {
    const handleFirstTab = e => {
      if (e.key === 'Tab') {
        element.classList.add('keyboard-navigation');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDown);
      }
    };
    const handleMouseDown = () => {
      element.classList.remove('keyboard-navigation');
      window.removeEventListener('mousedown', handleMouseDown);
      window.addEventListener('keydown', handleFirstTab);
    };
    window.addEventListener('keydown', handleFirstTab);
  }
  update(firstFocusableElement, currentFocusableElement, focusableElements, focusableElementOnLeftArrow) {
    this.focusableElements = focusableElements;
    this.firstFocusableElement = firstFocusableElement;
    this.currentFocusableElement = currentFocusableElement;
    this.lastFocusableElement = focusableElements[focusableElements.length - 1];
    this.focusableElementOnLeftArrow = focusableElementOnLeftArrow || this.focusableElementOnLeftArrow;
    this.currentFocusableElementIndex = focusableElements.indexOf(this.currentFocusableElement) > -1 ? focusableElements.indexOf(this.currentFocusableElement) : 0;
  }
  isItemVisible(htmlElement) {
    const computedStylesElement = getComputedStyle(htmlElement);
    return computedStylesElement.display !== 'none' && computedStylesElement.opacity !== '0';
  }
  removeHiddenHTMLElementsFromList(htmlElements) {
    return htmlElements.filter(htmlElement => {
      return this.isItemVisible(htmlElement);
    });
  }
  onUpdateKeyboardNavigationOnRightArrow(currentUniverse, focusableElements) {
    if (!currentUniverse) return;
    currentUniverse.removeEventListener('keyup', event => {
      this.updateKeyboardNavigationOnRightArrow(event, focusableElements, currentUniverse);
    });
    currentUniverse.addEventListener('keyup', event => {
      this.updateKeyboardNavigationOnRightArrow(event, focusableElements, currentUniverse);
    });
  }
  updateKeyboardNavigationOnRightArrow(event, focusableElements, currentUniverse) {
    if (event.key === ACCESSIBILITY.AUTHORIZED_KEY.ARROW_RIGHT || event.key === ACCESSIBILITY.AUTHORIZED_KEY.ENTER) {
      focusableElements = this.removeHiddenHTMLElementsFromList(focusableElements);
      this.update(focusableElements[0], focusableElements[0], focusableElements, currentUniverse);
      focusableElements[0] && focusableElements[0].focus();
    }
  }
}
export default AccessibilityClass;