var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import HttpModule from './http.module';
import { LAYER_NAVIGATION } from '../desktop/layer/layer-navigation.constants';
import { initSubLevelTracking, onError } from './common.tools';
export class LayerPrimaryNavigation {
  constructor(cacheName, cacheValidity, primaryElement = null) {
    this.cacheName = cacheName;
    this.cacheValidity = cacheValidity;
    this.primaryElement = primaryElement;
    if (this.primaryElement) {
      this.responseContainer = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.SUB_MENU_CONTENT_SELECTOR}`);
      this.mainLevelLabel = this.primaryElement.getAttribute('data-main-level');
    }
  }
  getData(url) {
    return __awaiter(this, void 0, void 0, function* () {
      const itemsNavigationResponse = yield HttpModule.get(url);
      const itemsNavigationResponseText = yield itemsNavigationResponse.text();
      return itemsNavigationResponseText;
    });
  }
  successLayerNavigation(htmlResponse, updateAccessibilityOnCloseSubUniverse) {
    this.loader && this.loader.classList.remove(LAYER_NAVIGATION.LOADER_SHOW_CSS);
    this.responseContainer.innerHTML = htmlResponse;
    initSubLevelTracking(this.subElementItems, this.mainLevelLabel);
    this.initOnCloseSubUniverse(updateAccessibilityOnCloseSubUniverse);
  }
  getSubItemFromServer(urlNavigation, successCallback) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const itemsNavigationResponseText = yield this.getData(urlNavigation);
        successCallback(itemsNavigationResponseText);
      } catch (error) {
        onError(error);
      }
    });
  }
  prepareLayerElements(cssShowClass) {
    if (!this.primaryElement) return;
    this.loader = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.LOADER_SELECTOR}`);
    this.subElementItems = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.SUB_MENU_CONTAINER_SELECTOR}`);
    this.subElementItems.classList.add(cssShowClass.container);
    this.loader.classList.add(cssShowClass.loader);
  }
  getUrl(element, urlAjax, device) {
    const {
      navigationCode,
      storeId,
      navigationParentCode
    } = element.dataset;
    if (!navigationCode || !device) return;
    let url = `${urlAjax}?navigationCode=${navigationCode}&device=${device}`;
    if (navigationParentCode) url += `&navigationParentCode=${navigationParentCode}`;
    if (storeId) url += `&store=${storeId}`;
    return url;
  }
  initOnCloseSubUniverse(updateAccessibilityOnCloseSubUniverse) {
    var _a;
    if (!this.primaryElement) return;
    (_a = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.BACK_BUTTON}`)) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => {
      this.subElementItems.classList.remove(`${LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK}`);
      updateAccessibilityOnCloseSubUniverse && updateAccessibilityOnCloseSubUniverse();
    });
  }
}