import { ACCESSIBILITY } from './accessibility-navigation.constants';
import AccessibilityClass from '../../common/accessibility.class';
import { LAYER_NAVIGATION } from '../layer/layer-navigation.constants';
import { isElementVisibleInContainer } from '../../../common/common.utils';
class AccessibilityNavigationModule extends AccessibilityClass {
  constructor() {
    super();
    this.logoElement = document.querySelector(`.${ACCESSIBILITY.LOGO_SELECTOR}`);
    this.mainNavigationComponent = document.querySelector(`.${ACCESSIBILITY.MAIN_NAVIGATION_COMPONENT}`);
  }
  init() {
    this.initEventFocusableElements();
    this.initFocusModeDetection(this.mainNavigationComponent);
  }
  initEventFocusableElements() {
    this.currentFocusableElementIndex = 0;
    document.addEventListener('keyup', this.handleKeyUpEvent.bind(this));
  }
  updateFocusableElements(focusableElementsLevel, listFocusableSubLevel = null) {
    this.listFocusableLevel = focusableElementsLevel;
    this.listFocusableSubLevel = listFocusableSubLevel;
    this.update(this.listFocusableLevel[0], this.listFocusableLevel[0], this.listFocusableLevel);
  }
  handleKeyUpEvent(event) {
    var _a;
    const {
      key
    } = event;
    const isAuthorizedKey = this.isAuthorisedKey(key);
    if (!isAuthorizedKey) return;
    const isLayerVisible = this.isLayerNavigationVisible();
    if (!isLayerVisible) return;
    if (this.focusableElements) {
      if (key === ACCESSIBILITY.AUTHORIZED_KEY.ARROW_DOWN || key === ACCESSIBILITY.AUTHORIZED_KEY.TAB && !event.shiftKey) {
        this.currentFocusableElementIndex = this.currentFocusableElementIndex < this.focusableElements.length - 1 ? ++this.currentFocusableElementIndex : 0;
      }
      if (key === ACCESSIBILITY.AUTHORIZED_KEY.ARROW_UP || key === ACCESSIBILITY.AUTHORIZED_KEY.TAB && event.shiftKey) {
        this.currentFocusableElementIndex = this.currentFocusableElementIndex === 0 ? this.focusableElements.length - 1 : --this.currentFocusableElementIndex;
      }
      (_a = this.focusableElements[this.currentFocusableElementIndex]) === null || _a === void 0 ? void 0 : _a.focus();
      this.currentFocusableElement = this.focusableElements[this.currentFocusableElementIndex];
    }
  }
  isLayerNavigationVisible() {
    const layerContainers = document.querySelectorAll(`.${ACCESSIBILITY.LAYER_CONTAINER_SELECTOR}`);
    return [...layerContainers].some(element => {
      const computedStylesElement = window.getComputedStyle(element);
      const visibilityCss = computedStylesElement.getPropertyValue('display');
      return visibilityCss === 'flex' || visibilityCss === 'block';
    });
  }
  isAuthorisedKey(key) {
    return Object.values(ACCESSIBILITY.AUTHORIZED_KEY).includes(key);
  }
  adjustTabindexForAccessibility() {
    const container = document.querySelector(`.${LAYER_NAVIGATION.SECONDARY_NAVIGATION}`);
    const items = container.querySelectorAll(`.${LAYER_NAVIGATION.SECONDARY_NAVIGATION_ITEM}`);
    items.forEach(link => {
      const parentElement = link.parentElement;
      if (isElementVisibleInContainer(container, link)) {
        link.setAttribute('tabindex', '0');
        parentElement.removeAttribute('aria-hidden');
      } else {
        link.setAttribute('tabindex', '-1');
        parentElement.setAttribute('aria-hidden', 'true');
      }
    });
  }
}
export default AccessibilityNavigationModule;